import { GetStaticPropsContext } from "next";
import Link from "next/link";

import { graphqlFetch } from "@shared/hooks";
import { CallsFilterVisibilityStatus } from "@shared/types";

import { TWizardCallsQuery, WizardCallsQuery } from "__generated__/types";

import * as constants from "src/constants";
import bundleMessages from "src/utils/bundleMessages";

export default function Home({ calls }: { calls: TWizardCallsQuery["calls"] }) {
  // TODO: This route is a no-op. Remove it and redirect to the main app.

  const multipleProjectsCalls = calls.filter((call) => {
    const { maxProjects } = call?.categories?.[0] ?? {};
    return typeof maxProjects === "number" && maxProjects > 1;
  });

  const singleImagesCategoryCalls = calls.filter((call) => {
    const { singleImagesCategory } = call?.categories?.[0] ?? {};
    return singleImagesCategory;
  });

  // Calls that are not single images category and have a maxProjects of 1
  const singleProjectsCalls = calls.filter((call) => {
    const { maxProjects, singleImagesCategory } = call?.categories?.[0] ?? {};
    return (
      !singleImagesCategory &&
      typeof maxProjects === "number" &&
      maxProjects === 1
    );
  });

  return (
    <main>
      <h1>Hello wizard!</h1>
      <Calls title="Multiple Projects" calls={multipleProjectsCalls} />
      <Calls title="Single Projects" calls={singleProjectsCalls} />
      <Calls title="Single Images Category" calls={singleImagesCategoryCalls} />
    </main>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = await bundleMessages(locale, [
    `${constants.MESSAGE_PREFIX}pages.index`,
  ]);

  const { calls }: TWizardCallsQuery = await graphqlFetch(
    JSON.stringify({
      query: WizardCallsQuery,
      variables: {
        limit: 100,
        filter: { visibilityStatus: CallsFilterVisibilityStatus.PUBLIC },
      },
    }),
  );

  const sswCalls = calls.filter(
    (call) =>
      // Get only the calls that use the new submission wizard
      call?.useNewSubmissionWizard &&
      // remove E2E calls
      !call?.title.en.includes("E2E"),
  );

  return {
    props: {
      messages,
      calls: sswCalls,
    },
    revalidate: 1,
  };
}

const CallItem = ({ call }: { call: TWizardCallsQuery["calls"][number] }) => {
  if (!call) return null;

  const isCallPaid = (call: TWizardCallsQuery["calls"][0]) => {
    const { fee = 0 } = call ?? {};
    const { price = 0, imagePrice = 0 } = call?.categories[0] ?? {};
    return fee || price || imagePrice;
  };

  return (
    <li key={call.id} className="py-1">
      <Link className="text-primary-600" href={`/${call.slug}/profile`}>
        {call.title.en} {call.allowsRevocation ? "✍️ " : " "}{" "}
        {isCallPaid(call) ? "💰" : ""}
      </Link>
      <details className="text-[10px] text-primary-400 cursor-pointer">
        <summary>Call Details</summary>
        {call.categories[0].singleImagesCategory
          ? "Single Image Category"
          : `Projects: Min ${call.categories[0].minProjects} - Max ${call.categories[0].maxProjects}`}
        <pre>{JSON.stringify(call, null, 2)}</pre>
      </details>
    </li>
  );
};

const Calls = ({
  title,
  calls,
}: {
  title: string;
  calls: TWizardCallsQuery["calls"];
}) => {
  return (
    <div className="my-2 py-2 border-t border-gray-200">
      <h2 className="text-lg font-semibold py-2">{title}</h2>
      <ul>
        {calls.map((call) => (
          <CallItem key={call?.id} call={call} />
        ))}
      </ul>
    </div>
  );
};
